import React, { Fragment, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles, Tooltip } from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import { fetchCleanNewMessages, fetchGetMessages } from '../../redux/message/actions'
import MessageEmpty from '@material-ui/icons/ChatBubble'
import MessageFull from '@material-ui/icons/Chat'
import IconButton from '@material-ui/core/IconButton'
import { fetchGetData } from '../../redux/test/actions'
import StorageIcon from '@material-ui/icons/Storage'
import clsx from 'clsx'
import { InfoTooltip, TransitionBottom } from './Style'
import DialogTitle from '@material-ui/core/DialogTitle'
import Box from '@material-ui/core/Box'
import CardMedia from '@material-ui/core/CardMedia'
import { API_URL, DATABASE, PUBLIC_URL } from '../../utils/config'
import { getParentDomain } from './Utils'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText/DialogContentText'
import Grid from '@material-ui/core/Grid'
import Badge from '@material-ui/core/Badge'
import StarsIcon from '@material-ui/icons/Stars'
import InfoIcon from '@material-ui/icons/Info'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import WarningIcon from '@material-ui/icons/Warning'
import ErrorIcon from '@material-ui/icons/Error'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import { fade } from '@material-ui/core/styles'
import { displayPopupMessage, generateErrorPart } from './Message'
import DatabaseInput from './DatabaseInput'
import ErrorBoundaryGuard from '../ErrorBoundaryGuard'
import { t } from 'i18next'
import { fetchConfigurations } from '../../redux/configuration/actions'
import { fetchChangeSettings } from '../../redux/settings/actions'
import { Trans } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  newMessageIcon: {
    color: '#ffc800',
    position: 'absolute',
    top: -10,
    right: 13,
  },
  header: {
    boxShadow: 'inset 0px 0px 82px 10px rgba(0,0,0,1)',
  },
  cardImg: {
    opacity: 0.8,
  },
  titleCard: {
    position: 'absolute',
    top: 55,
    left: 34,
    fontSize: 40,
    color: theme.palette.secondary.main,
    textShadow: '2px 1px 10px white',
  },
  badgeDatabase: {
    '& .MuiBadge-badge': {
      right: 9,
      top: 10,
    },
  },
}))

const DatabaseCmp = withRouter((props) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [databaseList, setDatabaseList] = React.useState([DATABASE()])
  const [configurationDatabase, setConfigurationDatabase] = React.useState(DATABASE())

  const [openDatabase, setOpenDatabase] = React.useState(false)

  useEffect(function () {
    let filter = encodeURIComponent(
      JSON.stringify([
        {
          property: 'name',
          value: '\\.batch',
          filterType: 'string',
          operator: 'like',
        },
      ]),
    )
    // if (openDatabase) {
    let url = `${API_URL()}/bo_classes/page/?filter=${filter}`
    fetch(url, {
      method: 'GET',
      credentials: 'include',
    })
      .then((response) => {
        if (!response.ok) {
          const statusText = response.statusText
          const status = response.status
          const url = response.url
          return response.text().then((errorMessage) => {
            const error = new Error(`${statusText} : ${errorMessage}`)
            if (response.headers.get('content-type') === 'application/json') {
              error.stack = JSON.stringify(
                JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                null,
                2,
              )
            } else {
              error.stack = new Error().stack
            }
            error.statusText = statusText
            error.status = status
            error.url = url
            throw error
          })
        }
        return response.json()
      })
      .then((json) => {
        let dataBaseList = json.result.map((db) => db.name.replace('.batch', ''))
        setDatabaseList(
          dataBaseList.sort(function (a, b) {
            if (a < b) return -1
            if (a > b) return 1
            return 0
          }),
        )
      })
      .catch((error) => {
        if (error.status === 403) {
          dispatch(fetchChangeSettings('loginPopup', true))
        }
        displayPopupMessage(
          dispatch,
          'error',
          t('view.common.databaseInput.title'),
          <Fragment>
            <div>{t('view.common.databaseInput.error')}</div>
            {generateErrorPart(error)}
            <div>{t('view.common.databaseInput.contact')}</div>
          </Fragment>,
        )
      })
    // }
  }, [])

  function handleCloseDatabase(evt, save) {
    setOpenDatabase(false)
    const isSomethingChanged =
      localStorage.getItem('SERVICE_dataBase_name') !== configurationDatabase
    if (save && isSomethingChanged) {
      localStorage.setItem('SERVICE_dataBase_name', configurationDatabase)
      displayPopupMessage(
        dispatch,
        'success',
        t('view.dialogs.config.close_msg.title'),
        <Fragment>
          <div>
            <Trans i18nKey="view.dialogs.config.close_msg.msg_database">
              The database is now:
              {{
                val: configurationDatabase,
              }}
            </Trans>
          </div>
        </Fragment>,
      )
      // Refresh the page
      window.location.reload()
    }
  }

  function handleOpenDatabase() {
    setOpenDatabase(true)
  }

  const handleConfigurationDatabaseChanged = (event) => {
    const value = event.target.value
    setConfigurationDatabase(value)
  }

  return (
    <div
      style={{
        margin: '0px 15px 0px 20px',
      }}
    >
      <Dialog
        open={openDatabase}
        onClose={(event) => handleCloseDatabase(event, false)}
        TransitionComponent={TransitionBottom}
        aria-labelledby="dialog-database"
        // fullWidth
        // maxWidth={'lg'}
      >
        <DialogTitle style={{ padding: 0 }}>
          <Box className={classes.header}>
            <CardMedia
              className={classes.cardImg}
              component="img"
              alt="header image"
              height="130"
              image={PUBLIC_URL + '/images/database_bkg.jpg'}
              title="Header image"
            />
            <span className={classes.titleCard}>{t('view.common.databaseCmp.database')}</span>
          </Box>
        </DialogTitle>
        <DialogContent style={{ overflow: 'hidden', minHeight: 60 }}>
          <DialogContentText style={{ marginTop: 20, marginBottom: 20 }}>
            {t('view.common.databaseCmp.msg1')}
          </DialogContentText>
          <ErrorBoundaryGuard isDialog>
            <FormControl
              style={{ background: 'white' }}
              required
              fullWidth
              variant="outlined"
              className={classes.formControlProfile}
              error={databaseList.indexOf(configurationDatabase) === -1}
            >
              <Select
                variant="outlined"
                onChange={handleConfigurationDatabaseChanged}
                value={configurationDatabase}
              >
                {databaseList.map((db) => (
                  <MenuItem key={db} value={db}>
                    {db}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </ErrorBoundaryGuard>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(event) => handleCloseDatabase(event, true)}
            color="primary"
            disabled={
              configurationDatabase === '' ||
              configurationDatabase === null ||
              configurationDatabase === undefined ||
              databaseList.indexOf(configurationDatabase) === -1
            }
          >
            {t('view.common.databaseCmp.save')}
          </Button>
          <Button onClick={(event) => handleCloseDatabase(event, false)} color="primary">
            {t('view.common.databaseCmp.cancel')}
          </Button>
        </DialogActions>
      </Dialog>
      <Tooltip
        arrow
        title={
          <div style={{ fontSize: 13, padding: 5 }}>
            {configurationDatabase === '' ||
            configurationDatabase === null ||
            configurationDatabase === undefined ||
            databaseList.indexOf(configurationDatabase) === -1
              ? t('view.common.databaseCmp.noDB')
              : DATABASE()}
          </div>
        }
      >
        <IconButton
          aria-label="database"
          style={{
            color: 'inherit',
          }}
          onClick={handleOpenDatabase}
        >
          <StorageIcon />
        </IconButton>
      </Tooltip>
    </div>
  )
})
export default DatabaseCmp
