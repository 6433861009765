import { API_BO_URL, FLASK_URL } from '../../utils/config'
import { getComparator, rebuildUnitaryAnalysesLims, stableSort } from '../../view/common/Utils'
import { fetchChangeSettings } from '../settings/actions'
import { displayPopupMessage, generateErrorPart } from '../../view/common/Message'
import React, { Fragment } from 'react'
import { receiveCurrentBatchLease } from '../batches/actions'
import { t } from 'i18next'

export const REQUEST_LIMS = 'REQUEST_LIMS'
export const DISPLAY_LIMS = 'DISPLAY_LIMS'
export const HIDE_LIMS = 'HIDE_LIMS'
export const RECEIVE_UNITARY_ANALYSES_LIMS = 'RECEIVE_UNITARY_ANALYSES_LIMS'
export const AUTHENTICATION_FAILURE = 'AUTHENTICATION_FAILURE'
export const REQUEST_AUTO_VALIDATION = 'REQUEST_AUTO_VALIDATION'
export const RECEIVE_AUTO_VALIDATION = 'RECEIVE_AUTO_VALIDATION'
export const REQUEST_IGNORE_FLAGS = 'REQUEST_IGNORE_FLAGS'
export const RECEIVE_IGNORED_FLAGS = 'RECEIVE_IGNORED_FLAGS'

export function requestLims() {
  return {
    type: REQUEST_LIMS,
  }
}
export function displayLims(lims) {
  return {
    type: DISPLAY_LIMS,
    visibility: true,
    lims: lims,
  }
}

export function receiveUnitaryAnalysesLims(
  jsonUasLIMS,
  unitaryAnalysesISTD,
  selection,
  hideGeneral,
  authenticationError,
) {
  // [#507] Merge not detected with detected to display the analysis line in export
  // lims table even if there is no UA detected inside
  const unitaryAnalyses = jsonUasLIMS.limsUA.concat(jsonUasLIMS.limsNonDetectedUA)
  // const unitaryAnalyses = jsonUasLIMS.limsUA
  // console.log('Number unitaryAnalyses: ' + unitaryAnalyses.length)
  const uas = unitaryAnalyses.map((unitAnalyse) => {
    // console.log('selection: ' + selection)
    // let analysis = selection.filter((s) => s._id === unitAnalyse.content.analysis.id)[0]
    // console.log('analysis: ' + analysis)
    return rebuildUnitaryAnalysesLims(unitAnalyse)
  })

  const selectionUpdated = selection
    .map(function (select) {
      let istdList = {
        ok: 0,
        ko: 0,
        none: 0,
        relance: 0,
      }
      select['limsUA'] = uas.filter((ua) => ua.analyseId === select._id)
      unitaryAnalysesISTD
        .filter((ua) => ua.content.analysis.id === select._id)
        .forEach((istd) => {
          switch (istd.content.validation) {
            case '0':
              istdList.none = istdList.none + 1
              break
            case '1':
              istdList.ok = istdList.ok + 1
              break
            case '2':
              istdList.ko = istdList.ko + 1
              break
            case '3':
              istdList.relance = istdList.relance + 1
              break
            default:
              break
          }
        })
      select['istd'] = istdList
      return select
    })
    .filter((selectionUpdated) => selectionUpdated.limsUA.length !== 0)

  for (var i = 0; i < selectionUpdated.length; i++) {
    let _analysis = selectionUpdated[i]
    let _uas_sum = []
    let _sum_names = []
    for (var j = 0; j < _analysis.limsUA.length; j++) {
      let _ua = _analysis.limsUA[j]
      if (_ua && _ua.molecule && _ua.molecule.res_sum) {
        let resSum = _ua.molecule.res_sum
        let conc = ''
        if (
          resSum &&
          resSum.reason &&
          resSum.reason.includes('Reported: ') &&
          resSum.reason.split('Reported: ')[1] !== undefined &&
          !isNaN(resSum.reason.split('Reported: ')[1])
        ) {
          conc = resSum.reason.split('Reported: ')[1]
        } else if (
          resSum &&
          resSum.reason &&
          resSum.reason.includes('Calculated: ') &&
          resSum.reason.split('Calculated: ')[1] !== undefined &&
          !isNaN(resSum.reason.split('Calculated: ')[1])
        ) {
          conc = resSum.reason.split('Calculated: ')[1]
        }
        // [#507] Add sum only for ua with validation as detected
        if (!_sum_names.includes(resSum.name) && _ua.validation === '1') {
          _uas_sum.push({
            name: resSum.name,
            eu_lmr: _ua.molecule.eu_lmr,
            coeff: resSum.coeff,
            reason: resSum.reason ? '' : resSum.reason,
            coeff_str: resSum.coeff_str,
            reported_conc: conc,
            analyse: _ua.analyse,
            analyseId: _ua.analyseId,
            reported:
              resSum.reason &&
              resSum.reason.includes('Reported: ') &&
              resSum.reason.split('Reported: ')[1] !== undefined &&
              !isNaN(resSum.reason.split('Reported: ')[1]),
          })
          _sum_names.push(resSum.name)
        }
      }
    }
    // add molecule somme
    if (_uas_sum.length > 0) {
      _analysis.limsSomme = _uas_sum
    }
  }

  const lims = {
    hideGeneral: hideGeneral,
    unitaryAnalysesLims: selectionUpdated,
    selection: selection,
    nonDetectedUA: jsonUasLIMS.limsNonDetectedUA,
  }
  return {
    type: authenticationError ? AUTHENTICATION_FAILURE : RECEIVE_UNITARY_ANALYSES_LIMS,
    visibility: true,
    lims: lims,
  }
}

export function hideLims() {
  return {
    type: HIDE_LIMS,
    visibility: false,
  }
}

export function fetchDisplayLims(selection, hideGeneral) {
  return async (dispatch) => {
    dispatch(requestLims())
    const lims = {
      selection: selection,
      hideGeneral: hideGeneral,
    }
    return dispatch(fetchUnitaryAnalysesLims(selection, hideGeneral))
  }
}

export function fetchHideLims() {
  return async (dispatch) => {
    dispatch(requestLims())
    return dispatch(hideLims())
  }
}

export function fetchUnitaryAnalysesLims(selection, hideGeneral) {
  return async (dispatch) => {
    dispatch(requestLims())
    const analysesId = selection
      .filter((analyse) => analyse.content.progression_requested !== 0)
      .map((sp) => sp._id)
    const batchId = selection[0].content.batch.id
    let sort = 'sort=content.molecule.name&dir=ASC'

    let filterISTD = [
      {
        property: 'content.batch.id',
        value: batchId,
        filterType: 'string',
        operator: 'eq',
      },
      {
        property: 'content.istd',
        value: 'True',
        filterType: 'string',
        operator: 'eq',
      },
    ]
    let filterConverted = encodeURIComponent(JSON.stringify(filterISTD))
    const responseISTD = await fetch(
      `${API_BO_URL()}.unitary_analysis/page/?${sort}&filter=${filterConverted}`,
      {
        method: 'GET',
        credentials: 'include',
      },
    )
    const authenticationError = responseISTD.status === 403
    if (authenticationError) {
      dispatch(fetchChangeSettings('loginPopup', true))
      throw new Error("You're not logged.")
    }
    const jsonISTD = await responseISTD.json()
    const resultISTD = jsonISTD.result
    const uasISTDFiltered = resultISTD.filter((ua) => analysesId.includes(ua.content.analysis.id))

    // GET not working then POST done
    const requestOptions = {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        batch_id: batchId,
        analyses_id: analysesId,
        body: { analyses_id: analysesId },
      }),
    }
    const responseUasLIMS = await fetch(`${FLASK_URL()}/analysis/report/uas_lims`, requestOptions)
    const authenticationErrorUasLIMS = responseUasLIMS.status === 403

    if (responseUasLIMS.status === 401 || !responseUasLIMS.ok) {
      const errorUasLIMS = await responseUasLIMS.text()
      const error = new Error(
        errorUasLIMS.message
          ? errorUasLIMS.message
          : errorUasLIMS.error && typeof errorUasLIMS.error === 'string'
          ? errorUasLIMS.error
          : '',
      )
      error.status = responseUasLIMS.status
      error.statusText = t('view.common.utils.lease_locked.code')
      throw error
    }
    const jsonUasLIMS = await responseUasLIMS.json()
    // const uasFiltered = jsonUasLIMS.limsUA

    //TODO update LIMS
    return dispatch(
      receiveUnitaryAnalysesLims(
        jsonUasLIMS,
        uasISTDFiltered,
        selection,
        hideGeneral,
        authenticationErrorUasLIMS || authenticationError,
      ),
    )
    // return result
  }
}

export function fetchAutoValidationLims(analysisId) {
  return async (dispatch) => {
    dispatch(requestAutoValidationLims())
    let filter = [
      {
        property: 'content.analysis.id',
        value: analysisId,
        filterType: 'string',
        operator: 'eq',
      },
      {
        property: 'content.validation',
        value: '0',
        filterType: 'string',
        operator: 'eq',
      },
    ]
    // Show/Hide unknown molecule
    if (
      localStorage.getItem('SETTINGS_show_unknown') &&
      localStorage.getItem('SETTINGS_show_unknown') === 'false'
    ) {
      filter.push({
        property: 'content.molecule.unknown',
        value: true,
        filterType: 'boolean',
        operator: 'ne',
      })
    }

    let sort = 'sort=name&dir=ASC'
    let p = encodeURIComponent(JSON.stringify(filter))
    return fetch(`${API_BO_URL()}.unitary_analysis/page/?${sort}&filter=${p}`, {
      method: 'GET',
      credentials: 'include',
    })
      .then((response) => {
        if (!response.ok) {
          const statusText = response.statusText
          const status = response.status
          const url = response.url
          return response.text().then((errorMessage) => {
            const error = new Error(`${statusText} : ${errorMessage}`)
            if (response.headers.get('content-type') === 'application/json') {
              error.stack = JSON.stringify(
                JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                null,
                2,
              )
            } else {
              error.stack = new Error().stack
            }
            error.statusText = statusText
            error.status = status
            error.url = url
            throw error
          })
        }
        return response.json()
      })
      .then((json) => {
        return dispatch(receiveAutoValidationLims(json.result, false))
      })
      .catch((error) => {
        if (error.status === 403) {
          dispatch(fetchChangeSettings('loginPopup', true))
          return dispatch(receiveAutoValidationLims([], true))
        }
        displayPopupMessage(
          dispatch,
          'error',
          'Failed to get UA',
          <Fragment>
            <div>An error does not allow to get UA:</div>
            {generateErrorPart(error)}
          </Fragment>,
        )
      })
  }
}
export function requestAutoValidationLims() {
  return {
    type: REQUEST_AUTO_VALIDATION,
  }
}
export function receiveAutoValidationLims(ua) {
  return {
    type: RECEIVE_AUTO_VALIDATION,
    autoValidated: ua,
  }
}

export function fetchIgnoreFlags(ua) {
  return async (dispatch) => {
    dispatch(requestIgnoreFLags())

    const requestOptions = {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        batch_id: ua.batchId,
        analyses_id: ua.analyseId,
        body: { unitary_analyses_id: ua._id },
      }),
    }
    const responseUaLIMS = await fetch(
      `${FLASK_URL()}/unitary_analysis/${ua._id}/flags/ignore`,
      requestOptions,
    )
    const authenticationErrorUasLIMS = responseUaLIMS.status === 403

    if (responseUaLIMS.status === 554 || !responseUaLIMS.ok) {
      // const errorUasLIMS = await responseUaLIMS.text()
      // const error = new Error(
      //   // errorUasLIMS.message
      //   //   ? errorUasLIMS.message
      //   //   : errorUasLIMS.error && typeof errorUasLIMS.error === 'string'
      //   //   ? errorUasLIMS.error
      //   //   : '',
      //   JSON.parse(errorUasLIMS).error,
      // )
      // error.code = responseUaLIMS.status
      // throw error

      return responseUaLIMS.text().then((text) => {
        throw JSON.parse(text).error
      })
    }
    const jsonUaLIMS = await responseUaLIMS.json()
    return dispatch(receiveIgnoredFlags(jsonUaLIMS, authenticationErrorUasLIMS))
    // return result
  }
}
export function requestIgnoreFLags() {
  return {
    type: REQUEST_IGNORE_FLAGS,
  }
}
export function receiveIgnoredFlags(ua) {
  return {
    type: RECEIVE_IGNORED_FLAGS,
    uaIgnoredFlags: ua,
  }
}
