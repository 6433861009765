import { AUTHENTICATION_FAILURE } from '../unitaryAnalyses/actions'
import { API_BO_URL } from '../../utils/config'
import { fetchChangeSettings } from '../settings/actions'
import { displayPopupMessage, generateErrorPart } from '../../view/common/Message'
import React, { Fragment } from 'react'

export const REQUEST_DETAIL = 'REQUEST_DETAIL'
export const DISPLAY_DETAIL = 'DISPLAY_DETAIL'
export const HIDE_DETAIL = 'HIDE_DETAIL'
export const REQUEST_CHANGE_DETAIL_ACTIVE_TAB = 'REQUEST_CHANGE_DETAIL_ACTIVE_TAB'
export const CHANGE_DETAIL_ACTIVE_TAB = 'CHANGE_DETAIL_ACTIVE_TAB'
export const CHANGE_DETAIL_CHANNEL = 'CHANGE_DETAIL_CHANNEL'
export const REQUEST_UNITARY_ANALYSES_CHANGE_LOCK_DETAIL =
  'REQUEST_UNITARY_ANALYSES_CHANGE_LOCK_DETAIL'
export const REQUEST_CHROMATO = 'REQUEST_CHROMATO'
export const RECEIVE_CHROMATO = 'RECEIVE_CHROMATO'
export const RECEIVE_UPDATE_UNITARY_ANALYSES_DETAIL = 'RECEIVE_UPDATE_UNITARY_ANALYSES_DETAIL'

export function requestDisplayDetail() {
  return {
    type: REQUEST_DETAIL,
  }
}
export function requestChangeDetailActiveTab() {
  return {
    type: REQUEST_CHANGE_DETAIL_ACTIVE_TAB,
  }
}
export function displayDetail(detail) {
  return {
    type: DISPLAY_DETAIL,
    visibility: true,
    detail: detail,
  }
}
export function changeDetailActiveTab(newActiveTab) {
  return {
    type: CHANGE_DETAIL_ACTIVE_TAB,
    newActiveTab: newActiveTab,
  }
}
export function hideDetail() {
  return {
    type: HIDE_DETAIL,
    visibility: false,
  }
}

export function fetchDisplayChromatoDetail(analyseId) {
  return async (dispatch) => {
    dispatch(requestChromato(analyseId))
    return fetch(`${API_BO_URL()}.unitary_analysis/${analyseId}`, {
      method: 'GET',
      credentials: 'include',
    })
      .then((response) => {
        if (!response.ok) {
          const statusText = response.statusText
          const status = response.status
          const url = response.url
          return response.text().then((errorMessage) => {
            const error = new Error(`${statusText} : ${errorMessage}`)
            if (response.headers.get('content-type') === 'application/json') {
              error.stack = JSON.stringify(
                JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                null,
                2,
              )
            } else {
              error.stack = new Error().stack
            }
            error.statusText = statusText
            error.status = status
            error.url = url
            throw error
          })
        }
        return response.json()
      })
      .then((json) => {
        return dispatch(receiveChromato(json, false))
      })
      .catch((error) => {
        if (error.status === 403) {
          dispatch(fetchChangeSettings('loginPopup', true))
        }
        displayPopupMessage(
          dispatch,
          'error',
          'Failed to get unitary analysis',
          <Fragment>
            <div>An error does not allow to get unitary analysis:</div>
            {generateErrorPart(error)}
          </Fragment>,
        )
      })
  }
}
export function requestChromato(analyseId) {
  return {
    type: REQUEST_CHROMATO,
    unitaryAnalysisId: analyseId,
  }
}
export function receiveChromato(unitaryAnalyses) {
  return {
    type: RECEIVE_CHROMATO,
    unitaryAnalysis: unitaryAnalyses,
  }
}

export function fetchDisplayDetail(item, activeTab, hideGeneral, anchorEl, collection) {
  return async (dispatch) => {
    dispatch(requestDisplayDetail())
    const detail = {
      item: item,
      activeTab: activeTab,
      hideGeneral: hideGeneral,
      anchorEl: anchorEl,
      collection: collection,
    }
    return dispatch(displayDetail(detail))
  }
}

export function fetchChangeDetailActiveTab(newActiveTab) {
  return async (dispatch) => {
    dispatch(requestChangeDetailActiveTab())
    return dispatch(changeDetailActiveTab(newActiveTab))
  }
}

export function fetchHideDetail() {
  return async (dispatch) => {
    dispatch(requestDisplayDetail())
    return dispatch(hideDetail())
  }
}

export function fetchChangeSelectedChannelDetail(channel) {
  return async (dispatch) => {
    return dispatch({
      type: CHANGE_DETAIL_CHANNEL,
      channel: channel,
    })
  }
}

export function fetchChangeLockDetail(uaId) {
  return async (dispatch) => {
    return dispatch({
      type: REQUEST_UNITARY_ANALYSES_CHANGE_LOCK_DETAIL,
      uaId: uaId,
    })
  }
}

export function receiveUpdateUnitaryAnalysesDetail(json, channel, collection, authenticationError) {
  return {
    type: authenticationError ? AUTHENTICATION_FAILURE : RECEIVE_UPDATE_UNITARY_ANALYSES_DETAIL,
    uaChannel: channel,
    uaUpdated: json,
    uaCollection: collection,
  }
}
