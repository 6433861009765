import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import Loading from '../Loading'
import CardMedia from '@material-ui/core/CardMedia'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import { PUBLIC_URL } from '../../utils/config'
import ChromatoDetail from './chromatoDetail/ChromatoDetail'
import FlagsDetail from './FlagsDetail'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import PropTypes from 'prop-types'
import Popover from '@material-ui/core/Popover'
import { fetchChangeDetailActiveTab, fetchHideDetail } from '../../redux/detail/actions'
import ErrorBoundaryGuard from '../ErrorBoundaryGuard'
import { t } from 'i18next'
import { canIModifyTheCalibration } from './Utils'
import { getBatchById } from '../../redux/batches/selectors'

const styles = (theme) => ({
  header: {
    boxShadow: 'inset 0px 0px 82px 10px rgba(0,0,0,1)',
    textAlign: 'center',
  },
  title: {
    width: '100%',
    position: 'absolute',
    top: 22,
    left: 0,
    fontSize: 30,
    color: theme.palette.secondary.main,
    textShadow: '2px 1px 6px white',
  },
  titleISTD: {
    margin: 24,
    padding: '4px 8px',
    border: '2px solid ' + theme.palette.secondary.main,
    borderRadius: 10,
    boxShadow: '2px 1px 6px white',
  },
  cardImg: {
    opacity: 0.8,
  },
  bouton: {
    position: 'absolute',
    right: 10,
  },
  mainContainer: {
    height: 'calc(100% - 152px)',
    overflow: 'hidden',
    margin: 5,
    '& > div > .MuiBox-root': {
      padding: 0,
      overflow: 'hidden',
    },
  },
  tabPanel: {
    height: '100%',
    '& > .MuiBox-root': {
      height: '100%',
    },
    '& > .MuiBox-root > .MuiTypography-root': {
      height: '100%',
    },
  },
  appBar: {
    backgroundColor: 'transparent',
    top: 52,
    boxShadow: 'none',
    '& .MuiTabs-indicator': {
      height: 4,
    },
    '& button .MuiTab-wrapper': {
      textShadow: '1px 1px 6px #00000087',
    },
  },
  popoverContainer: {
    background: '#00000021',
    '& > .MuiPopover-paper': {
      height: 'calc(100% - 50px)',
      width: 'calc(100% - 50px)',
      top: '25px !important',
      left: '25px !important',
      // padding: 20,
      overflow: 'hidden',
    },
  },
})

const mapStateToProps = (state, ownProps) => {
  const { detail, global, batches } = state
  // const batch = getBatchById(batches, ownProps.match.params.batchId)
  const batchID = detail?.detail?.item?.batchId
  const batch = getBatchById(batches, batchID)

  return {
    batch,
    showDetail: detail.visibility,
    item: detail.detail.item,
    activeTab: detail.detail.activeTab,
    hideGeneral: detail.detail.hideGeneral,
    anchorEl: detail.detail.anchorEl,
    collection: detail.detail.collection,
    featuresConfig: global.featuresConfig,
  }
}

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export default withRouter(
  connect(mapStateToProps)(
    withStyles(styles)(
      class Detail extends React.Component {
        constructor(props) {
          super(props)
          this.state = {
            showDetail: false,
            item: null,
            activeTab: 0,
            hideGeneral: false,
            anchorEl: null,
          }
          this.getSelectedChannel = this.getSelectedChannel.bind(this)
        }

        canEditCalib(item) {
          const { batch, featuresConfig } = this.props
          const edit_calibration = featuresConfig?.content?.edit_calibration
          const param_exist = typeof edit_calibration === 'boolean'
          const getRights = canIModifyTheCalibration(batch)
          // In this case we want to know if the item can be edited or not
          if (item) {
            if (item.sampleType === 'Standard') {
              return param_exist ? edit_calibration && getRights : true
            } else {
              return true
            }
          } else {
            // Here it's to know if the edition is active or not
            return param_exist ? edit_calibration && getRights : true
          }
        }

        handleChangeActiveTab = (event, newValue) => {
          const { dispatch } = this.props
          event.stopPropagation()
          event.preventDefault()
          dispatch(fetchChangeDetailActiveTab(newValue))
        }

        handleCloseFlagsDetail = (event) => {
          const { dispatch } = this.props
          event.stopPropagation()
          event.preventDefault()
          dispatch(fetchHideDetail())
        }

        getSelectedChannel(uaId) {
          const { item } = this.props
          // const selCh = data.filter((ua) => ua._id === uaId)[0]
          return item.selectedChannel
        }

        render() {
          const {
            dispatch,
            classes,
            showDetail,
            activeTab,
            item,
            hideGeneral,
            anchorEl,
            collection,
          } = this.props
          if (!item) {
            return (
              <Popover
                id={undefined}
                open={false}
                anchorEl={null}
                className={classes.popoverContainer}
              >
                <Loading mode={'miniCircle'} />
              </Popover>
            )
          }

          const id = showDetail ? 'simple-popover' : undefined

          return (
            <Popover
              id={id}
              open={showDetail}
              anchorEl={anchorEl.isConnected ? anchorEl : document.getElementById('myAnalyseList')}
              onClose={(event) => this.handleCloseFlagsDetail(event)}
              className={classes.popoverContainer}
            >
              <Box className={classes.header}>
                <CardMedia
                  className={classes.cardImg}
                  component="img"
                  alt="header image"
                  height="100"
                  image={PUBLIC_URL + '/images/detail_img_blur.jpg'}
                  title="Header image"
                />
                <span className={classes.title}>
                  {/*{item.name + (collection === 'compound' ? ' / ' + item.analyse : '')}*/}
                  {item.name}
                  {collection === 'compound' && (
                    <span className={classes.titleISTD}>{item.analyse}</span>
                  )}
                </span>
              </Box>
              <AppBar className={classes.appBar} position="absolute">
                <Tabs
                  value={hideGeneral ? 0 : activeTab}
                  onChange={this.handleChangeActiveTab}
                  aria-label="simple tabs example"
                >
                  {hideGeneral ? (
                    ''
                  ) : (
                    <Tab label={t('view.common.detail.general')} {...a11yProps(0)} />
                  )}
                  <Tab label={t('view.common.detail.flags')} {...a11yProps(hideGeneral ? 0 : 1)} />
                </Tabs>
              </AppBar>
              <Box className={classes.mainContainer}>
                {hideGeneral ? (
                  ''
                ) : (
                  <TabPanel
                    className={classes.tabPanel}
                    value={hideGeneral ? 0 : activeTab}
                    index={0}
                  >
                    <ErrorBoundaryGuard>
                      <ChromatoDetail
                        canEdit={this.canEditCalib(item)}
                        item={item}
                        collection={collection}
                        getSelectedChannel={this.getSelectedChannel}
                      />
                    </ErrorBoundaryGuard>
                  </TabPanel>
                )}
                <TabPanel
                  className={classes.tabPanel}
                  value={hideGeneral ? 0 : activeTab}
                  index={hideGeneral ? 0 : 1}
                >
                  <ErrorBoundaryGuard>
                    <FlagsDetail item={item} />
                  </ErrorBoundaryGuard>
                </TabPanel>
              </Box>
              <Button
                color="primary"
                className={classes.bouton}
                onClick={(event) => this.handleCloseFlagsDetail(event)}
              >
                {t('view.common.detail.close')}
              </Button>
            </Popover>
          )
        }
      },
    ),
  ),
)
