import React, { Fragment, useEffect, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { genericStyles, TaskTooltipMessage, TransitionBottom } from './Style'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import Box from '@mui/material/Box'
import CardMedia from '@mui/material/CardMedia'
import {
  API_BO_URL,
  CONFIGURATION_PROFILE,
  DRAWER_WIDTH,
  GET_SECONDARY_COLOR,
  PUBLIC_URL,
} from '../../utils/config'
import DialogContent from '@mui/material/DialogContent'
import Button from '@mui/material/Button'
import clsx from 'clsx'
import IconButton from '@mui/material/IconButton'
import DialogActions from '@mui/material/DialogActions'
import { withRouter } from 'react-router-dom'
import { fetchGetMessages } from '../../redux/message/actions'
import { Trans, useTranslation } from 'react-i18next'
import makeStyles from '@material-ui/core/styles/makeStyles'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import SettingsIcon from '@material-ui/icons/Settings'
import { ClickAwayListener, Paper } from '@mui/material'
import WarningIcon from '@mui/icons-material/Warning'
import Stack from '@mui/material/Stack'
import DialogContentText from '@mui/material/DialogContentText'
import ReactJson from 'react-json-view'
import * as PropTypes from 'prop-types'
import Divider from '@material-ui/core/Divider'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import { styled } from '@mui/material/styles'
import NumbersIcon from '@mui/icons-material/Numbers'
import FontDownloadIcon from '@mui/icons-material/FontDownload'
import EditIcon from '@mui/icons-material/Edit'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import CompressIcon from '@mui/icons-material/Compress'
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates'
import { alpha } from '@material-ui/core'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import Table from '@mui/material/Table'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import CancelIcon from '@mui/icons-material/Cancel'
import { fetchUpdateConfiguration } from '../../redux/configuration/actions'
import { displayPopupMessage } from './Message'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import RotateLeftIcon from '@mui/icons-material/RotateLeft'
import SplitterLayout from 'react-splitter-layout'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'

import TreeView from '@mui/lab/TreeView'
import TreeItem, { useTreeItem } from '@mui/lab/TreeItem'
import Typography from '@mui/material/Typography'
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import ErrorBoundaryGuard from '../ErrorBoundaryGuard'

const useStyles = makeStyles((theme) => ({
  titleCard: {
    position: 'absolute',
    top: 55,
    left: 34,
    fontSize: 40,
    color: 'white',
    textShadow: '2px 1px 10px white',
  },
  button: {
    // '& .expertMode': {
    //   display: 'none',
    // },
    // '& .normalMode': {
    //   display: 'block',
    // },
    // '&:hover .normalMode': {
    //   display: 'none',
    // },
    // '&:hover .expertMode': {
    //   display: 'block',
    // },
    '& .flip-box-inner': {
      position: 'relative',
      width: 60,
      height: 60,
      transition: 'transform 0.4s',
      transformStyle: 'preserve-3d',
    },
    '&:hover .flip-box-inner': {
      transform: 'rotateY(180deg)',
    },
    '& .flip-box-front, .flip-box-back': {
      position: 'absolute',
      width: '100%',
      height: '100%',
      '-webkit-backface-visibility': 'hidden',
      backfaceVisibility: 'hidden',
    },
    '& .flip-box-back': {
      position: 'relative',
      transform: 'rotateY(180deg)',
    },
  },
  warningColor: {
    color: theme.palette.warning.main,
  },
  customRootDialog: {
    fontSize: 14,
    height: '80%',
  },
  customConfirmDialog: {
    maxHeight: '70%',
  },
  infoPaper: {
    color: theme.palette.secondary.main,
    '& .MuiTableCell-body': {
      color: 'white',
      padding: '5px 14px',
      border: 'none',
    },
  },
  collapsedCmp: {
    margin: '0px 6px',
    color: '#ffffffd4',
    '& .MuiInput-underline:before, & .MuiInput-underline:hover:before, & .MuiInput-underline:after': {
      border: 'none',
    },
    '& .MuiSelect-select.MuiSelect-select, & .MuiSelect-icon': {
      color: '#ffffffd4',
    },
  },
  reactJsonCmp: {
    '& .react-json-view': {
      height: '100%',
      overflow: 'auto',
      backgroundColor: '#2e2f28 !important',
    },
    '& .react-json-view .key-modal-request > div': {
      zIndex: 1,
      position: 'fixed !important',
      marginTop: '66px  !important',
      marginLeft: '377px !important',
    },
  },
  firstSplitter: {
    height: 'calc(100% - 182px)',
    '& > .layout-pane': {
      // padding: '1px 8px 14px 0px',
      overflow: 'hidden',
      paddingLeft: 10,
    },
    '& > .layout-pane:first-child': {
      padding: '17px 0px 2px 0px',
      minWidth: 200,
    },
  },
  customTreeItem: {
    '& > .MuiTreeItem-content:hover': {
      backgroundColor: alpha(theme.palette.secondary.main, 0.5),
    },
    '& > .Mui-selected, & > .Mui-selected.Mui-focused, & > .Mui-selected:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  customTreeRootItem: {
    '& > .MuiTreeItem-content:hover': {
      backgroundColor: 'transparent',
      cursor: 'default',
    },
    '& > .Mui-selected, & > .Mui-selected.Mui-focused, & > .Mui-selected:hover': {
      backgroundColor: 'transparent',
      cursor: 'default',
    },
  },
  questionTitle: {
    color: theme.palette.secondary.main,
  },
  configurationTitle: {
    marginTop: 6,
    color: theme.palette.secondary.main,
  },
}))

const secondaryColor = GET_SECONDARY_COLOR()

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    margin: 0,
    border: 0,
    '&.MuiButtonBase-root': {
      margin: 1,
      color: '#ffffffd4',
    },
    '&.MuiButtonBase-root.Mui-selected': {
      backgroundColor: alpha(secondaryColor, 0.2),
      color: secondaryColor,
      margin: 1,
    },
    '&.MuiDivider-root': {
      height: '60%',
      top: '20%',
      position: 'relative',
      backgroundColor: '#ffffff1f',
    },
    '&.Mui-disabled': {
      border: 0,
    },
    '&:not(:first-of-type)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-of-type': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}))

const ConfigurationExpert = withRouter((props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const genericClasses = genericStyles()
  const refConfigMenu = useRef()

  const filterProperties = localStorage.getItem('__config.json__CONFIGURATION_KEYS')
    ? localStorage.getItem('__config.json__CONFIGURATION_KEYS').split(',')
    : []
  //   [
  //   'data_client',
  //   'peak_detection',
  //   'analysis_parameters_',
  //   'calibration_parameters_',
  //   'ai_ignored',
  //   'calibration_suffixes',
  //   'default_vol_to_mass_formula',
  // ]

  const defaultProps = {
    theme: 'monokai',
    src: null,
    collapsed: 1,
    collapseStringsAfter: false,
    onAdd: false,
    onEdit: false,
    onDelete: false,
    displayObjectSize: false,
    enableClipboard: false,
    indentWidth: 4,
    displayDataTypes: false,
    iconStyle: 'triangle',
    info: true,
  }

  const configurations = useSelector((state) => state.configuration.configurations)

  // const configuration = useSelector(function (state) {
  //   return state.configuration.configurations.filter(
  //     (config) => config.name === CONFIGURATION_PROFILE(),
  //   )[0]
  //   return configurationFiltered
  // })

  // const configurationFiltered = useMemo(() => {
  //   let result = {}
  //   if (configuration) {
  //     Object.entries(configuration.content).forEach((cfdb) => {
  //       if (filterProperties.findIndex((prop) => cfdb[0].indexOf(prop) !== -1) !== -1) {
  //         result[cfdb[0]] = cfdb[1]
  //       }
  //     })
  //   }
  //   return result
  // }, [configuration])

  const [openExpert, setOpenExpert] = React.useState(false)
  const [openConfirm, setOpenConfirm] = React.useState(false)
  const [openSaveConfirm, setOpenSaveConfirm] = React.useState(false)
  const [collapsed, setCollapsed] = React.useState(1)
  const [options, setOptions] = React.useState(() => ['info'])
  const [properties, setProperties] = React.useState(() => defaultProps)
  const [expanded, setExpanded] = React.useState([])

  const [currentConfigSelected, setCurrentConfigSelected] = React.useState()

  const canIEditTheConfiguration = useMemo(() => {
    if (currentConfigSelected) {
      const user = JSON.parse(localStorage.getItem('SESSION_user_json'))
      if (user.admin) {
        return true
      }
      const configTeams = configurations.filter((conf) => conf._id === currentConfigSelected)?.[0]
        ?.teams
      const userTeams = user.teams
      const userTeamsForConfig = userTeams.filter((ut) => configTeams.includes(ut.id))
      return !userTeamsForConfig.every((utfb) => utfb.role !== 'admin')
    }
    return false
  }, [configurations, currentConfigSelected])

  useEffect(() => {
    if (openExpert && !canIEditTheConfiguration) {
      displayPopupMessage(
        dispatch,
        'info',
        <Trans i18nKey="view.common.configurationExpert.dialog.expert.title">
          {{
            val: configurations.filter((conf) => conf._id === currentConfigSelected)[0]
              ? configurations.filter((conf) => conf._id === currentConfigSelected)[0].name
              : '',
          }}
          configuration
        </Trans>,
        t('view.common.configurationExpert.dialog.expert.rights'),
      )
    }
  }, [openExpert, canIEditTheConfiguration, currentConfigSelected, configurations])

  // useEffect(() => {
  //   setConfigurationJson(configurationFiltered)
  // }, [currentConfigSelected])

  // const configuration = useMemo(() => {
  //   return configurations.filter((config) => config._id === currentConfigSelected)[0]
  // }, [configurations, currentConfigSelected])

  // const configurationFiltered = useMemo(() => {
  //   let result = {}
  //   if (configuration) {
  //     Object.entries(configuration.content).forEach((cfdb) => {
  //       if (filterProperties.findIndex((prop) => cfdb[0].indexOf(prop) !== -1) !== -1) {
  //         result[cfdb[0]] = cfdb[1]
  //       }
  //     })
  //   }
  //   return result
  // }, [configuration])

  const configurationsFiltered = useMemo(() => {
    let globalResult = {}
    if (configurations.length > 0) {
      globalResult = Object.assign(
        {},
        ...configurations.map(function (configuration) {
          let result = {}
          Object.entries(configuration.content).forEach((cfdb) => {
            if (filterProperties.findIndex((prop) => cfdb[0].indexOf(prop) !== -1) !== -1) {
              result[cfdb[0]] = cfdb[1]
            }
          })
          return {
            [configuration._id]: result,
          }
        }),
      )
    }
    return globalResult
  }, [configurations])

  // const [configurationJson, setConfigurationJson] = React.useState(configurationFiltered)
  const [configurationsJson, setConfigurationsJson] = React.useState(configurationsFiltered)

  // const jsonDifferences = useMemo(() => {
  //   return jsonDiff(configurationFiltered, configurationJson)
  // }, [JSON.stringify(configurationJson)])
  const jsonsDifferences = useMemo(() => {
    return Object.assign(
      {},
      ...Object.keys(configurationsFiltered).map(function (configurationFiltered_id) {
        return {
          [configurationFiltered_id]: jsonDiff(
            configurationsFiltered[configurationFiltered_id],
            configurationsJson[configurationFiltered_id],
          ),
        }
      }),
    )
  }, [JSON.stringify(configurationsJson)])

  const jsonsDifferencesToConfirm = useMemo(() => {
    const tmp = JSON.parse(JSON.stringify(jsonsDifferences))
    // Object.keys(tmp).forEach((jd_id) => {
    //   if (tmp[jd_id] === undefined) {
    //     delete tmp[jd_id]
    //   }
    // })
    const tmpKeys = Object.keys(tmp)
    tmpKeys.forEach((jd_id) => {
      const conf = configurations.filter((conf) => conf._id === jd_id)[0]
      if (conf) {
        tmp[conf.name] = tmp[jd_id]
        delete tmp[jd_id]
      }
    })
    return tmp
  }, [JSON.stringify(jsonsDifferences)])

  useEffect(() => {
    let currentConf = null
    // Apply the current config in case of a batch is opened
    if (props.location.pathname !== '/') {
      setCurrentConfigSelected(CONFIGURATION_PROFILE())
      currentConf = configurations.filter((conf) => conf._id === CONFIGURATION_PROFILE())[0]
    }
    setConfigurationsJson(configurationsFiltered)
    if (currentConf) {
      function getPath(paths, id) {
        const config = configurations.filter((conf) => conf._id === id)[0]
        if (config && config.content.parent) {
          const parentId = config.content.parent.id
          getPath(paths, parentId)
        }
        paths.push(id)
        return paths
      }

      setExpanded(getPath(['root'], currentConf._id))
    } else {
      setExpanded(['root'])
    }
  }, [openExpert])

  function handleCloseExpert() {
    // Clear tmp data
    setConfigurationsJson(configurationsFiltered)
    setOpenExpert(false)
  }
  function handleOpenExpert() {
    setOpenExpert(true)
  }
  function handleSaveExpert() {
    setOpenSaveConfirm(true)
  }
  function handleOpenConfirm() {
    setOpenConfirm(true)
  }
  function handleCloseConfirm() {
    setOpenConfirm(false)
  }
  function handleCloseContinue() {
    setOpenConfirm(false)
    handleOpenExpert()
  }
  function handleCloseSaveConfirm() {
    setOpenSaveConfirm(false)
  }

  function handleSaveConfirm() {
    function fetchByPromiseNew(oldConfig, newConfigPart) {
      const mergeResult = { ...oldConfig, content: { ...oldConfig.content, ...newConfigPart } }
      return dispatch(fetchUpdateConfiguration(mergeResult))
    }

    const arr = Object.keys(jsonsDifferences)
      .filter((jd) => jsonsDifferences[jd] !== undefined)
      .map((configId) =>
        fetchByPromiseNew(
          configurations.filter((config) => config._id === configId)[0],
          configurationsJson[configId],
        ),
      )
    Promise.all(arr).then(
      function (returns) {
        const updatedConfiguration = returns
          .filter((config) => config.type === 'RECEIVE_UPDATE_CONFIGURATION')
          .map((conf) => conf.configurationUpdated.name)
        const notUpdatedConfiguration = returns
          .filter((config) => config.type !== 'RECEIVE_UPDATE_CONFIGURATION')
          .map((conf) => conf.configurationUpdated.name)
        displayPopupMessage(
          dispatch,
          'success',
          t('view.common.configurationExpert.popupMessage.title'),
          <Fragment>
            {updatedConfiguration.length > 0 && (
              <Trans i18nKey="view.common.configurationExpert.popupMessage.msg">
                The {{ val: updatedConfiguration.join(', ') }} configurations was updated.
              </Trans>
            )}
            {notUpdatedConfiguration.length > 0 && (
              <div>
                <Trans i18nKey="view.common.configurationExpert.popupMessage.msgError">
                  The {{ val: notUpdatedConfiguration.join(', ') }} configurations wasn't updated.
                </Trans>
              </div>
            )}
          </Fragment>,
        )
        setOpenSaveConfirm(false)
        setOpenExpert(false)
      },
      function (error) {
        setOpenSaveConfirm(false)
        setOpenExpert(false)
      },
    )

    //Save on DB
    // const oldConfig = configuration
    // const newConfigPart = configurationJson
    // const mergeResult = { ...oldConfig, content: { ...oldConfig.content, ...newConfigPart } }
    // dispatch(fetchUpdateConfiguration(mergeResult)).then(function (resp) {
    //   if (resp.type === 'RECEIVE_UPDATE_CONFIGURATION') {
    //     displayPopupMessage(
    //       dispatch,
    //       'success',
    //       t('view.common.configurationExpert.popupMessage.title'),
    //       <Fragment>
    //         <Trans i18nKey="view.common.configurationExpert.popupMessage.msg">
    //           The {{ val: resp.configurationUpdated.name }} configuration was updated.
    //         </Trans>
    //       </Fragment>,
    //     )
    //     setOpenSaveConfirm(false)
    //     setOpenExpert(false)
    //   }
    // })
  }

  function handleOption(event, newOptions) {
    setOptions(newOptions)
  }

  function onContentFirstSplitterSizeSave() {
    localStorage.setItem(
      'LAYOUT_configuration_paneSize',
      refConfigMenu.current.state.secondaryPaneSize,
    )
  }

  useEffect(() => {
    setProperties({
      ...defaultProps,
      displayDataTypes: options.indexOf('type') !== -1,
      displayObjectSize: options.indexOf('size') !== -1,
      onEdit: options.indexOf('edit') !== -1,
      onAdd: options.indexOf('add') !== -1,
      onDelete: options.indexOf('delete') !== -1,
      enableClipboard: options.indexOf('clipboard') !== -1,
      info: options.indexOf('info') !== -1,
    })
  }, [options])

  function handleCollapsedChange(event) {
    setCollapsed(event.target.value)
  }

  function resetModification() {
    setConfigurationsJson(configurationsFiltered)
  }

  function jsonDiff(originJson, modifiedJson) {
    const result = {}
    if (Object.is(originJson, modifiedJson)) {
      return undefined
    }
    if (!modifiedJson || typeof modifiedJson !== 'object') {
      return modifiedJson
    }
    Object.keys(originJson || {})
      .concat(Object.keys(modifiedJson || {}))
      .forEach((key) => {
        let originDoesNotExist = false
        let destinatinationDoesNotExist = false
        if (
          modifiedJson[key] !== originJson[key] &&
          !Object.is(originJson[key], modifiedJson[key])
        ) {
          // result[key] = { 'Old value': obj1[key], 'New value': obj2[key] }
          if (modifiedJson[key] === undefined) {
            // Case for a deleted prop
            destinatinationDoesNotExist = true
            result[key] = JSON.stringify(originJson[key])
          } else if (originJson[key] === undefined) {
            // Case for a new prop
            originDoesNotExist = true
            result[key] = JSON.stringify(modifiedJson[key])
          } else {
            // Case for a modified value
            result[key] = JSON.stringify(['#', originJson[key], '$SEP$', modifiedJson[key], '#'])
              .replace('["#",', '#@$#START#')
              .replace(',"#"]', '#END#@$#')
              .replaceAll('","$SEP$","', '"#SEPARATOR#"')
              .replaceAll(',"$SEP$",', '#SEPARATOR#')
          }
        }
        if (typeof modifiedJson[key] === 'object' && typeof originJson[key] === 'object') {
          const value = jsonDiff(originJson[key], modifiedJson[key])
          if (value !== undefined) {
            result[key] = value
          }
        }
        if (originDoesNotExist) {
          result['#ORIGINDOESNOTEXISTSTART#' + key] =
            '#ORIGIN_D_N_E#' + result[key] + '#ORIGINDOESNOTEXISTEND#'
          delete result[key]
        }
        if (destinatinationDoesNotExist) {
          result['#DESTINATIONDOESNOTEXISTSTART#' + key] =
            '#DESTINATION_D_N_E#' + result[key] + '#DESTINATIONDOESNOTEXISTEND#'
          delete result[key]
        }
      })
    return result
  }

  function convert(diffs) {
    if (diffs) {
      return diffs
        .replaceAll('\\"', '"')
        .replaceAll('"#ORIGINDOESNOTEXISTSTART#', '#ORIGINDOESNOTEXIST#"')
        .replaceAll('#ORIGINDOESNOTEXISTEND#"', '#ORIGINDOESNOTEXIST#')
        .split('#ORIGINDOESNOTEXIST#')
        .map((oldOriginSplited) => {
          if (oldOriginSplited.indexOf('#ORIGIN_D_N_E#') !== -1) {
            return (
              <span style={{ color: 'green', display: 'inline-block' }}>
                <AddCircleOutlineIcon style={{ margin: '-8px 5px -8px -17px', width: 12 }} />
                {/*<span>{oldOriginSplited.replace('"#ORIGIN_D_N_E#', '').replaceAll('\\n', '<br>')}</span>*/}
                {/*<span style={{ marginLeft: 15 }}>*/}
                <span>
                  {JSON.stringify(
                    JSON.parse('{' + oldOriginSplited.replace('"#ORIGIN_D_N_E#', '') + '}'),
                    null,
                    2,
                  )}
                </span>
              </span>
            )
          } else {
            return oldOriginSplited
              .replaceAll('"#DESTINATIONDOESNOTEXISTSTART#', '#DESTINATIONDOESNOTEXIST#"')
              .replaceAll('#DESTINATIONDOESNOTEXISTEND#"', '#DESTINATIONDOESNOTEXIST#')
              .split('#DESTINATIONDOESNOTEXIST#')
              .map((originSplited) => {
                if (originSplited.indexOf('#DESTINATION_D_N_E#') !== -1) {
                  return (
                    <span style={{ color: 'red', display: 'inline-block' }}>
                      <HighlightOffIcon style={{ margin: '-8px 5px -8px -17px', width: 12 }} />
                      <span>
                        {JSON.stringify(
                          JSON.parse('{' + originSplited.replace('"#DESTINATION_D_N_E#', '') + '}'),
                          null,
                          2,
                        )}
                      </span>
                    </span>
                  )
                } else {
                  let result = originSplited
                    .replaceAll('"#@$#START#', '#@$#')
                    .replaceAll('#END#@$#"', '#@$#')
                  result = result.split('#@$#')
                  return result.map((res) => {
                    if (res.indexOf('#SEPARATOR#') !== -1) {
                      const resultSplited = res.split('#SEPARATOR#')
                      return (
                        <span>
                          <span style={{ color: 'red' }}>{resultSplited[0]}</span>
                          <ArrowRightAltIcon style={{ margin: '-8px 5px' }} />
                          <span style={{ color: 'green' }}>{resultSplited[1]}</span>
                        </span>
                      )
                    } else {
                      return res
                    }
                  })
                }
              })
          }
        })
    } else {
      return '{}'
    }
  }

  function createTreeFromList(jsonList) {
    function addItemInNode(node, item) {
      let result = -1
      for (let i = 0; i < node.length; i++) {
        if (item.parent && item.parent.id) {
          if (node[i].id === item.parent.id) {
            node[i].children.push(item)
            if (result === -1) {
              result = i
            }
            i = node.length
          } else if (node[i].children.length > 0) {
            if (result === -1) {
              result = addItemInNode(node[i].children, item)
            }
          }
        }
      }
      return result
    }

    function recursive(itemToPositioned) {
      for (let i = 0; i < itemToPositioned.length; i++) {
        const result = addItemInNode(itemToPositioned, itemToPositioned[i])
        if (result !== -1) {
          // Node found a parent, remove it from the list
          itemToPositioned = itemToPositioned.filter((item) => item.id !== itemToPositioned[i].id)
          i = itemToPositioned.length
        }
      }
      // continue if there is not all parent
      if (!itemToPositioned.every((i) => !(i.parent && i.parent.id))) {
        return recursive(itemToPositioned)
      } else {
        return itemToPositioned
      }
    }

    // Créer une copie de la liste d'objets pour éviter les modifications indésirables
    let clonedList = JSON.parse(JSON.stringify(jsonList))
      .map((obj) => ({
        id: obj._id,
        name: obj.name,
        children: [],
        parent: obj.content.parent,
      }))
      .sort(function (a, b) {
        if (a.name < b.name) return -1
        if (a.name > b.name) return 1
        return 0
      })

    return recursive(clonedList)
  }

  const handleSelect = (event, nodeId) => {
    setCurrentConfigSelected(nodeId)
  }
  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds)
  }

  const CustomContent = React.forwardRef(function CustomContent(props, ref) {
    const { classes, className, label, nodeId, icon: iconProp, expansionIcon, displayIcon } = props

    const {
      disabled,
      expanded,
      selected,
      focused,
      handleExpansion,
      handleSelection,
      preventSelection,
    } = useTreeItem(nodeId)

    const icon = iconProp || expansionIcon || displayIcon

    const handleMouseDown = (event) => {
      if (event.target.localName === 'path') {
        preventSelection(event)
      } else {
        setCurrentConfigSelected(nodeId)
      }
    }

    const handleExpansionClick = (event) => {
      if (event.target.localName === 'path' || event.target.localName === 'svg') {
        handleExpansion(event)
      } else {
        handleSelection(event)
      }
    }

    const handleSelectionClick = (event) => {
      handleSelection(event)
    }

    return (
      <div
        className={clsx(className, classes.root, {
          [classes.expanded]: expanded,
          [classes.selected]: selected,
          [classes.focused]: focused,
          [classes.disabled]: disabled,
        })}
        // onMouseDown={nodeId !== 'root' ? handleMouseDown : null}
        ref={ref}
        style={{ opacity: nodeId !== 'root' ? 1 : 0, height: nodeId !== 'root' ? 24 : 0 }}
      >
        <div
          onClick={nodeId !== 'root' ? handleExpansionClick : null}
          className={classes.iconContainer}
          style={{ height: 23, paddingTop: 2 }}
        >
          {icon}
        </div>
        <Typography
          onClick={nodeId !== 'root' ? handleSelectionClick : null}
          component="div"
          className={classes.label}
        >
          {label}
        </Typography>
      </div>
    )
  })

  CustomContent.propTypes = {
    /**
     * Override or extend the styles applied to the component.
     */
    classes: PropTypes.object.isRequired,
    /**
     * className applied to the root element.
     */
    className: PropTypes.string,
    /**
     * The icon to display next to the tree node's label. Either a parent or end icon.
     */
    displayIcon: PropTypes.node,
    /**
     * The icon to display next to the tree node's label. Either an expansion or collapse icon.
     */
    expansionIcon: PropTypes.node,
    /**
     * The icon to display next to the tree node's label.
     */
    icon: PropTypes.node,
    /**
     * The tree node label.
     */
    label: PropTypes.node,
    /**
     * The id of the node.
     */
    nodeId: PropTypes.string.isRequired,
  }

  const CustomTreeItem = (props) => (
    <TreeItem
      className={props.nodeId !== 'root' ? classes.customTreeItem : classes.customTreeRootItem}
      ContentComponent={CustomContent}
      {...props}
    />
  )

  const renderTree = (nodes) => (
    <CustomTreeItem
      // className={classes.customTreeItem}
      // key={nodes.id}
      nodeId={nodes.id}
      label={nodes.name}
      // onLabelClick={function (event) {
      //   setCurrentConfigSelected(nodes.name)
      // }}
    >
      {Array.isArray(nodes.children) ? nodes.children.map((node) => renderTree(node)) : null}
    </CustomTreeItem>
  )

  return (
    <Fragment>
      <Dialog
        open={openConfirm}
        disablePortal={true}
        onClose={handleCloseConfirm}
        TransitionComponent={TransitionBottom}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className={classes.questionTitle}>
          {t('view.common.configurationExpert.dialog.expertConfirm.title') + '?'}
        </DialogTitle>
        <DialogContent style={{ marginTop: 15 }}>
          <DialogContentText>
            <Stack direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={3}>
              <WarningIcon color="warning" style={{ height: 50, width: 50 }} />
              <div>
                <div>{t('view.common.configurationExpert.dialog.expertConfirm.msg1') + '.'}</div>
                <div style={{ marginTop: 10 }}>
                  {t('view.common.configurationExpert.dialog.expertConfirm.msg2') + '.'}
                </div>
                <div style={{ marginTop: 20 }}>
                  {t('view.common.configurationExpert.dialog.expertConfirm.msg3') + '?'}
                </div>
              </div>
            </Stack>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseContinue} autoFocus>
            {t('view.common.configurationExpert.dialog.expertConfirm.button.continue')}
          </Button>
          <Button onClick={handleCloseConfirm}>
            {t('view.common.configurationExpert.dialog.expertConfirm.button.cancel')}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openExpert}
        disablePortal={true}
        onClose={handleCloseExpert}
        TransitionComponent={TransitionBottom}
        aria-labelledby="dialog-title-user"
        fullWidth
        maxWidth={'lg'}
        classes={{
          paper: classes.customRootDialog,
        }}
      >
        <DialogTitle style={{ padding: 0 }}>
          <Box className={classes.header}>
            <CardMedia
              className={classes.cardImg}
              component="img"
              alt="header image"
              height={130}
              image={PUBLIC_URL + '/images/messages_bkg_2.jpg'}
              title="Header image"
            />
            <span className={classes.titleCard}>
              <Trans i18nKey="view.common.configurationExpert.dialog.expert.title">
                {{
                  val: configurations.filter((conf) => conf._id === currentConfigSelected)[0]
                    ? configurations.filter((conf) => conf._id === currentConfigSelected)[0].name
                    : '',
                }}
                configuration
              </Trans>
            </span>
            <AdminPanelSettingsIcon className={genericClasses.icon} />
          </Box>
        </DialogTitle>
        <DialogContent
          style={{ padding: 0, background: '#424242', minHeight: 222 }}
          className={classes.reactJsonCmp}
        >
          <SplitterLayout
            customClassName={classes.firstSplitter}
            ref={refConfigMenu}
            onDragEnd={onContentFirstSplitterSizeSave}
            secondaryMinSize={220}
            primaryMinSize={460}
            primaryIndex={1}
            secondaryInitialSize={
              localStorage.getItem('LAYOUT_configuration_paneSize')
                ? parseFloat(localStorage.getItem('LAYOUT_configuration_paneSize'))
                : 220
            }
          >
            {/*{createTreeFromList(configurations)}*/}
            <ErrorBoundaryGuard>
              <TreeView
                defaultCollapseIcon={<ArrowDropDownIcon style={{ color: '#66d9ef' }} />}
                defaultExpandIcon={<ArrowRightIcon style={{ color: '#ae81ff' }} />}
                defaultExpanded={['root']}
                sx={{ height: 110, flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
                style={{ height: '100%', overflow: 'auto', color: 'white' }}
                selected={currentConfigSelected}
                onNodeSelect={handleSelect}
                expanded={expanded}
                onNodeToggle={handleToggle}
              >
                {renderTree({
                  id: 'root',
                  name: 'Configurations',
                  children: createTreeFromList(configurations),
                })}
              </TreeView>
            </ErrorBoundaryGuard>
            <Fragment>
              <ErrorBoundaryGuard>
                <ReactJson
                  // name={configuration ? configuration.name : false}
                  name={false}
                  collapsed={collapsed >= 1 ? collapsed : collapsed === 0}
                  style={{ padding: 20, paddingTop: 67 }}
                  theme={properties.theme}
                  // src={configurationJson}
                  src={configurationsJson[currentConfigSelected]}
                  collapseStringsAfterLength={properties.collapseStringsAfter}
                  onEdit={
                    properties.onEdit
                      ? (e) => {
                          // setConfigurationJson(e.updated_src)
                          setConfigurationsJson((previousConfig) => {
                            return { ...previousConfig, [currentConfigSelected]: e.updated_src }
                          })
                        }
                      : false
                  }
                  onDelete={
                    properties.onDelete
                      ? (e) => {
                          // setConfigurationJson(e.updated_src)
                          setConfigurationsJson((previousConfig) => {
                            return { ...previousConfig, [currentConfigSelected]: e.updated_src }
                          })
                        }
                      : false
                  }
                  onAdd={
                    properties.onAdd
                      ? (e) => {
                          // setConfigurationJson(e.updated_src)
                          setConfigurationsJson((previousConfig) => {
                            return { ...previousConfig, [currentConfigSelected]: e.updated_src }
                          })
                        }
                      : false
                  }
                  displayObjectSize={properties.displayObjectSize}
                  enableClipboard={properties.enableClipboard}
                  indentWidth={properties.indentWidth}
                  displayDataTypes={properties.displayDataTypes}
                  iconStyle={properties.iconStyle}
                />
              </ErrorBoundaryGuard>
              <Paper
                elevation={0}
                sx={{
                  display: 'flex',
                  border: (theme) => `1px solid ${theme.palette.divider}`,
                  flexWrap: 'wrap',
                }}
                style={{ position: 'absolute', top: 10, left: 20, backgroundColor: '#424242' }}
              >
                <StyledToggleButtonGroup
                  size="small"
                  value={options}
                  onChange={handleOption}
                  aria-label="text formatting"
                >
                  <ToggleButton value="type" aria-label="type">
                    <Tooltip
                      arrow
                      placement="top"
                      title={
                        <div style={{ fontSize: 13, padding: 5 }}>
                          {t('view.common.configurationExpert.dialog.expert.tooltip.type')}
                        </div>
                      }
                    >
                      <FontDownloadIcon />
                    </Tooltip>
                  </ToggleButton>
                  <ToggleButton value="size" aria-label="size">
                    <Tooltip
                      arrow
                      placement="top"
                      title={
                        <div style={{ fontSize: 13, padding: 5 }}>
                          {t('view.common.configurationExpert.dialog.expert.tooltip.size')}
                        </div>
                      }
                    >
                      <NumbersIcon />
                    </Tooltip>
                  </ToggleButton>
                  <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />
                  <ToggleButton value="edit" aria-label="edit" disabled={!canIEditTheConfiguration}>
                    <Tooltip
                      arrow
                      placement="top"
                      title={
                        <div style={{ fontSize: 13, padding: 5 }}>
                          {t('view.common.configurationExpert.dialog.expert.tooltip.edit')}
                        </div>
                      }
                    >
                      <EditIcon style={{ opacity: canIEditTheConfiguration ? 1 : 0.2 }} />
                    </Tooltip>
                  </ToggleButton>
                  <ToggleButton value="add" aria-label="add" disabled={!canIEditTheConfiguration}>
                    <Tooltip
                      arrow
                      placement="top"
                      title={
                        <div style={{ fontSize: 13, padding: 5 }}>
                          {t('view.common.configurationExpert.dialog.expert.tooltip.add')}
                        </div>
                      }
                    >
                      <AddCircleIcon style={{ opacity: canIEditTheConfiguration ? 1 : 0.2 }} />
                    </Tooltip>
                  </ToggleButton>
                  <ToggleButton
                    value="delete"
                    aria-label="delete"
                    disabled={!canIEditTheConfiguration}
                  >
                    <Tooltip
                      arrow
                      placement="top"
                      title={
                        <div style={{ fontSize: 13, padding: 5 }}>
                          {t('view.common.configurationExpert.dialog.expert.tooltip.delete')}
                        </div>
                      }
                    >
                      <CancelIcon style={{ opacity: canIEditTheConfiguration ? 1 : 0.2 }} />
                    </Tooltip>
                  </ToggleButton>
                  {/*<ToggleButton value="clipboard" aria-label="clipboard">*/}
                  {/*  <Tooltip*/}
                  {/*    arrow*/}
                  {/*    placement="top"*/}
                  {/*    title={<div style={{ fontSize: 13, padding: 5 }}>Enable clipboard</div>}*/}
                  {/*  >*/}
                  {/*    <ContentPasteIcon />*/}
                  {/*  </Tooltip>*/}
                  {/*</ToggleButton>*/}
                  <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />
                  <FormControl
                    sx={{ m: 1, minWidth: 120 }}
                    style={{ margin: '5px 5px 0px' }}
                    className={classes.collapsedCmp}
                  >
                    <Select value={collapsed} onChange={handleCollapsedChange}>
                      <MenuItem value={0}>
                        <Stack
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                          spacing={0.5}
                        >
                          <CompressIcon />
                          <span>
                            {t('view.common.configurationExpert.dialog.expert.menu.collapse')}
                          </span>
                        </Stack>
                      </MenuItem>
                      <MenuItem value={-1}>
                        <Stack
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                          spacing={0.5}
                        >
                          <CompressIcon />
                          <span>
                            {t('view.common.configurationExpert.dialog.expert.menu.uncollapse')}
                          </span>
                        </Stack>
                      </MenuItem>
                      <MenuItem value={1}>
                        <Stack
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                          spacing={0.5}
                        >
                          <CompressIcon />
                          <span>
                            {t('view.common.configurationExpert.dialog.expert.menu.depth1')}
                          </span>
                        </Stack>
                      </MenuItem>
                      <MenuItem value={2}>
                        <Stack
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                          spacing={0.5}
                        >
                          <CompressIcon />
                          <span>
                            {t('view.common.configurationExpert.dialog.expert.menu.depth2')}
                          </span>
                        </Stack>
                      </MenuItem>
                    </Select>
                  </FormControl>
                  <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />
                  <ToggleButton value="info" aria-label="info">
                    <Tooltip
                      arrow
                      placement="top"
                      title={
                        <div style={{ fontSize: 13, padding: 5 }}>
                          {t('view.common.configurationExpert.dialog.expert.tooltip.keyboard')}
                        </div>
                      }
                    >
                      <TipsAndUpdatesIcon />
                    </Tooltip>
                  </ToggleButton>
                </StyledToggleButtonGroup>
              </Paper>
              <Paper
                elevation={0}
                sx={{
                  display: 'flex',
                  border: (theme) => `1px solid ${theme.palette.divider}`,
                  flexWrap: 'wrap',
                }}
                style={{
                  display: properties.info ? 'block' : 'none',
                  position: 'absolute',
                  top: 10,
                  right: 25,
                  backgroundColor: '#424242',
                }}
              >
                <div style={{ fontSize: 13, padding: 5 }} className={classes.infoPaper}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                  >
                    <div
                      style={{ fontWeight: 600, fontSize: 18, marginLeft: 14, marginBottom: 10 }}
                    >
                      {t('view.common.configurationExpert.dialog.expert.keyboard.title')}
                    </div>
                    <IconButton
                      color="inherit"
                      style={{ padding: 0, marginRight: 10, marginBottom: 10 }}
                      onClick={() => {
                        setOptions(options.filter((option) => option !== 'info'))
                      }}
                    >
                      <CancelPresentationIcon fontSize="inherit" />
                    </IconButton>
                  </Stack>
                  <TableContainer>
                    <Table>
                      <TableBody>
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell>
                            {t('view.common.configurationExpert.dialog.expert.keyboard.edit')}
                          </TableCell>
                          <TableCell>:</TableCell>
                          <TableCell>ctrl + click</TableCell>
                        </TableRow>
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell>
                            {t('view.common.configurationExpert.dialog.expert.keyboard.submit')}
                          </TableCell>
                          <TableCell>:</TableCell>
                          <TableCell>ctrl + Enter</TableCell>
                        </TableRow>
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell>
                            {t('view.common.configurationExpert.dialog.expert.keyboard.cancelEdit')}
                          </TableCell>
                          <TableCell>:</TableCell>
                          <TableCell>Escape</TableCell>
                        </TableRow>
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell>
                            {t('view.common.configurationExpert.dialog.expert.keyboard.add')}
                          </TableCell>
                          <TableCell>:</TableCell>
                          <TableCell>Enter</TableCell>
                        </TableRow>
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell>
                            {t('view.common.configurationExpert.dialog.expert.keyboard.cancelAdd')}
                          </TableCell>
                          <TableCell>:</TableCell>
                          <TableCell>Escape</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </Paper>
            </Fragment>
          </SplitterLayout>
        </DialogContent>
        <DialogActions style={{ padding: 8 }}>
          <Button
            variant="contained"
            className={classes.deletePrefsButton}
            startIcon={<RotateLeftIcon />}
            onClick={resetModification}
            // disabled={JSON.stringify(jsonDifferences) === undefined}
            disabled={Object.keys(jsonsDifferences).every(
              (jd) => jsonsDifferences[jd] === undefined,
            )}
          >
            {t('view.common.configurationExpert.dialog.expert.button.clear')}
          </Button>
          <div style={{ flex: 'auto' }}></div>
          <Button
            autoFocus
            size={'medium'}
            onClick={handleSaveExpert}
            color="primary"
            // disabled={JSON.stringify(jsonDifferences) === undefined}
            disabled={Object.keys(jsonsDifferences).every(
              (jd) => jsonsDifferences[jd] === undefined,
            )}
          >
            {t('view.common.configurationExpert.dialog.expert.button.save')}
          </Button>
          <Button size={'medium'} onClick={handleCloseExpert} color="primary">
            {t('view.common.configurationExpert.dialog.expert.button.cancel')}
          </Button>
        </DialogActions>
      </Dialog>
      {openSaveConfirm && (
        <Dialog
          open={openSaveConfirm}
          fullWidth
          maxWidth={'md'}
          disablePortal={true}
          TransitionComponent={TransitionBottom}
          onClose={handleCloseSaveConfirm}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          classes={{
            paper: classes.customConfirmDialog,
          }}
        >
          <DialogTitle className={classes.questionTitle}>
            {t('view.common.configurationExpert.dialog.expertSave.title') + '?'}
          </DialogTitle>
          <DialogContent style={{ marginTop: 15 }}>
            <DialogContentText>
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={3}
              >
                <WarningIcon color="warning" style={{ height: 50, width: 50 }} />
                <div style={{ overflow: 'hidden', width: '100%' }}>
                  <div style={{ marginBottom: 10 }}>
                    {t('view.common.configurationExpert.dialog.expertSave.msg1') + ':'}
                  </div>
                  {Object.entries(jsonsDifferencesToConfirm).map((entry) => (
                    <Fragment>
                      <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={1}
                        className={classes.configurationTitle}
                      >
                        <DescriptionOutlinedIcon />
                        <div>{entry[0]}</div>
                      </Stack>
                      <pre
                        style={{
                          fontSize: 13,
                          lineHeight: '16px',
                          background: '#efefef',
                          padding: 7,
                          margin: '0px 0px 20px',
                          color: '#444444',
                          overflow: 'auto',
                          maxHeight: 360,
                          width: 'calc(100% - 85px)',
                        }}
                      >
                        {convert(JSON.stringify(entry[1], null, 2))}
                      </pre>
                    </Fragment>
                  ))}
                  {/*<pre*/}
                  {/*  style={{*/}
                  {/*    fontSize: 13,*/}
                  {/*    lineHeight: '16px',*/}
                  {/*    background: '#efefef',*/}
                  {/*    padding: 7,*/}
                  {/*    margin: '6px 0px 30px',*/}
                  {/*    color: '#444444',*/}
                  {/*    overflow: 'auto',*/}
                  {/*    maxHeight: 360,*/}
                  {/*    width: 'calc(100% - 85px)',*/}
                  {/*  }}*/}
                  {/*>*/}
                  {/*  {convert(JSON.stringify(jsonsDifferences, null, 2))}*/}
                  {/*</pre>*/}
                  <div>{t('view.common.configurationExpert.dialog.expertSave.msg2') + '.'}</div>
                  <div style={{ marginTop: 20 }}>
                    {t('view.common.configurationExpert.dialog.expertSave.msg3') + '?'}
                  </div>
                </div>
              </Stack>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSaveConfirm} autoFocus>
              {t('view.common.configurationExpert.dialog.expertSave.button.save')}
            </Button>
            <Button onClick={handleCloseSaveConfirm}>
              {t('view.common.configurationExpert.dialog.expertSave.button.cancel')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <div className={clsx(genericClasses.icon, classes.button)}>
        <Tooltip
          arrow
          placement="top"
          title={
            <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
              <WarningIcon />
              <span style={{ fontSize: 17 }}>{t('view.common.configurationExpert.tooltip')}</span>
            </Stack>
          }
        >
          <IconButton
            aria-label="messages"
            style={{ color: 'inherit', width: 'inherit', height: 'inherit' }}
            onClick={handleOpenConfirm}
            size="large"
          >
            <div className={'flip-box-inner'}>
              <SettingsIcon
                color="inherit"
                style={{ width: 'inherit', height: 'inherit' }}
                className={clsx('normalMode', 'flip-box-front')}
              />
              <AdminPanelSettingsIcon
                color="inherit"
                style={{ width: 'inherit', height: 'inherit' }}
                className={clsx('expertMode', 'flip-box-back')}
              />
            </div>
          </IconButton>
        </Tooltip>
      </div>
    </Fragment>
  )
})
export default ConfigurationExpert
